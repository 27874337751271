import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	H4,
	Icon,
	IconApprove,
	InlineText,
	Paragraph,
	TextContainer,
	Tooltip,
	Ul,
} from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { CodeBlock } from "../../../../../src/docs/docs-code";
import { Checkbox, BaseCheckbox, CheckboxGroup } from "./checkbox";

export const Meta: DocPageMeta = {
	category: "Forms and input",
	title: "Checkbox",
	notepad: "https://hackmd.io/2FLPwv9dSi61gdkqL4QmaQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Checkbox"
			subTitle="Checkboxes allow users to select one or more items from a list."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Checkbox} />
				<Header.H2>Examples</Header.H2>
				<Header.H3>Base checkbox</Header.H3>
				<Paragraph>
					This interactive input control indicates the current state. By default, it is unchecked.
				</Paragraph>
				<Example fn={BaseCheckboxExample} />
				<Header.H3>Checkbox</Header.H3>
				<Paragraph>
					Provide a label indicating to users what can be selected or deselected.
				</Paragraph>
				<Example fn={CheckboxExample} />
				<Header.H3>Checkbox group</Header.H3>
				<Paragraph>
					When using <Code>CheckboxGroup</Code>, provide a group label to indicate the category of
					the options and explain what can be selected.
				</Paragraph>
				<Ul
					items={[
						<>
							<H4>Default state:</H4>
							by default, checkboxes are unchecked, meaning no options have been selected. Use
							checked state when the item is selected.
						</>,
						<>
							<H4>Indeterminate state:</H4>
							use the indeterminate state when you have a list of selections, where some items are
							are selected, and some are unselected.
						</>,
						<>
							<H4>Disabled state:</H4>
							when the user expects a predefined set of options, a disabled option is useful to
							indicate that normally that option would be available, but now it is not available due
							to a specific condition. If possible, provide hint text or a visual cue to checkbox is
							disabled to avoid confusion for the user.
						</>,
						<>
							<H4>Label with dynamic text:</H4>
							be aware of how the labels will resize and how any visuals surrounding them will be
							affected.
						</>,
						<>
							<H4>Label with icon or tooltip:</H4>
							help the user understand the context of the checkbox.
						</>,
					]}
				/>

				<Example fn={CheckboxGroupExample} />
				<Header.H3>Horizontal checkbox group</Header.H3>
				<Paragraph>
					Make a horizontal group by using the <Code>direction</Code> prop on the{" "}
					<Code>CheckboxGroup</Code> component.
				</Paragraph>
				<Example fn={CheckboxGroupHorizontalExample} />
			</TextContainer>
			<TextContainer article>
				<Header.H2>Properties</Header.H2>
				<Header.H3>Base checkbox</Header.H3>
				<Knobs
					component={BaseCheckbox}
					initialProps={({ setState }) => ({
						checked: false,
						onChange: (v) => setState({ checked: v.target.checked }),
					})}
				/>
				<Header.H3>Checkbox</Header.H3>
				<Knobs
					component={Checkbox}
					initialProps={({ setState }) => ({
						checked: false,
						onChange: (v) => setState({ checked: v.target.checked }),
						children: "Label",
						value: "value",
					})}
				/>
				<Header.H3>Checkbox group</Header.H3>
				<Knobs
					component={CheckboxGroup}
					initialProps={({ setState }) => ({
						children: (
							<>
								<CheckboxGroup.Checkbox value="white-wine">White Wine</CheckboxGroup.Checkbox>
								<CheckboxGroup.Checkbox value="red-wine">Red Wine</CheckboxGroup.Checkbox>
							</>
						),
						value: [],
						onChange: (v) => setState({ value: v }),
					})}
				/>
			</TextContainer>
			<TextContainer article>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Checkbox</Code> or <Code>CheckboxGroup</Code> when
					</Paragraph>
					<Ul
						items={[
							"multiple choices are available, and users can choose zero, one, or multiple items from a list. ",
							"seven or fewer related items can be selected from a list. ",
							"the user needs to confirm, save changes, or take explicit action. ",
							"the user can enable or disable a single feature.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>Checkboxes are typically used in the following places:</Paragraph>
					<Ul
						items={[
							<>
								<H4>Forms</H4>
								Checkboxes can be used in forms on a full page, in modals, or in side panels. To
								group multiple options, checkboxes can be placed in the{" "}
								<GatsbyLink to="/lab/components/Forms and input/Form element wrapper">
									Form element wrapper
								</GatsbyLink>{" "}
								component or by using the{" "}
								<GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink>{" "}
								component in the multi-select mode. An example of a checkbox in forms can be found
								in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FSEOv2%2F446001%2FKeywordMonitoring%2FIndex%3Flang%3Den-US">
									Keyword Monitoring
								</a>
								.
							</>,
							<>
								<H4>Filtering and bulk action</H4>
								Checkboxes can be used to filter information on a page, in modals, in forms, or
								within components. Checkboxes can be used in the{" "}
								<GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink>{" "}
								component when using custom option elements. An example of filtering can be found in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FSEOv2%2F446001%2FActivityPlansOverview%2FIndex%3FTabSelect%3DIndex.ActivityPlansOverview%26lang%3Den-US">
									Activity Plan Overview
								</a>
								.
							</>,
							<>
								<H4>Terms and conditions</H4>
								Users can indicate whether they agree or disagree with the terms by ticking or
								unticking a standalone checkbox. Ticked or unticked a checkbox also indicates that
								an option is enabled or disabled (e.g. "Remember me on this computer"). Examples of
								terms and conditions can be found on the page of{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FIntegrations%2FOverlay%2FIndex%3Flang%3Den-US">
									CMS Plugin Setup
								</a>
								.
							</>,
							<>
								<H4>Line Chart</H4>
								Checkboxes can be used to toggle the visibility of lines on a chart. Examples of a
								chart can be found on the page of{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FQualityAssurance%2F446001%2FOverview%2FIndex%3Flang%3Den-US">
									QA overview
								</a>
								.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							"Checkbox labels are recommended to align to the right of their corresponding base checkboxes. (Cohesive) ",
							"Checkbox groups can be positioned vertically or horizontally depending on the use case and the UI structure. Whenever possible, arrange the checkboxes vertically to make it easier for the user to scan and compare the options, especially in a form. (Intuitive)",
							"Checkbox labels can be wrapped into two lines if they are long and viewed on a mobile screen size. A checkbox label should never be truncated, omitted, or replaced with “…”. (Accessible) ",
							"Checkbox groups should not be nested. Ensure that all options are on the same level to prevent confusion. (Intuitive)",
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								The user can only choose one item from a list. In this case, use{" "}
								<GatsbyLink to="/lab/components/Forms and input/Radios">radios</GatsbyLink> instead.
							</>,
							<>
								Each of the items represents an independent feature or behaviour and is not related
								to each other.
							</>,
							<>The user does not need to confirm, save, or take explicit action.</>,
							<>
								The user requires an immediate response or change in the settings. In this case, use
								the{" "}
								<GatsbyLink to="/lab/components/Actions and controls/Toggle Switch">
									Toggle Switch
								</GatsbyLink>{" "}
								component instead.{" "}
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<H4>For designers</H4>
					<Ul
						items={[
							<>
								A checkbox should have a <a href="#checkbox">label</a>.
							</>,
							<>
								A checkbox group must have a meaningful name as an <Code>aria-label</Code>, which
								helps the screen reader properly announce the group of checkboxes to make it clear
								that they belong together. To understand what is <Code>aria-label</Code>, please{" "}
								<a href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label">
									visit this link
								</a>
								.
							</>,
							"If possible, add a hint text under the checkbox label to improve the clarity of the action and add further context to the label.",
							<>
								For <Code>BaseCheckbox</Code> within the form, containing 10, or more options, add
								buttons for selecting and deselecting all options. For example: “Select 25 links on
								this page”, or “Select all 35 links”.
							</>,
						]}
					/>
					<H4>For developers</H4>
					<Ul
						items={[
							<>
								A checkbox must have a visible label. For example:{" "}
								<Code>{`<Checkbox value="Apple">Apple</Checkbox>`}</Code>
							</>,
							<>
								A group of checkboxes must be contained in a <Code>CheckboxGroup</Code> component.
							</>,
							<>
								To allow the screen reader to announce a <Code>CheckboxGroup</Code> meaningfully,
								the text of the <Code>aria-label</Code> must be provided by a designer.
							</>,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"It is recommended that the checkbox label contains three words or less.",
							"Use clear and concise checkbox labels and group labels.",
							"Use sentence case for a group label.",
							"Do not include punctuation after a checkbox label.",
							"Use positive and affirmative labels. For example: “Remember password” instead of “Forget password”.",
							"Whenever possible, provide a hint text indicating how many items users should select from a list of checkboxes. For example: “Select all that apply”, or “Select at least one item”.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
			<TextContainer article>
				<Header.H2>Notable changes</Header.H2>
				<Header.H3>Migrating from FancyLab 16.x to FancyLab 17.x</Header.H3>
				<Paragraph>
					The <Code>CheckboxGroup</Code> component on FancyLab 16.x or prior takes an array of{" "}
					<Code>control</Code> objects as input/property, which is terse and works well with
					typechecking, but it plays poorly with composition. As an example, adding a tooltip to a
					checkbox label is not possible using this structure as well as changing the icon position
					to after or before the label.
				</Paragraph>
				<Paragraph>
					So, in FancyLab 17.x, the <Code>CheckboxGroup</Code> component was refactored to allow
					composing other components with them. Now, instead of having a controls object,
					subcomponents are used to create the controls.
				</Paragraph>
				<Paragraph>
					Please find below an example that illustrates the usage before and after these changes.
					Note that the <Code>controls</Code> property has been replaced by children of type{" "}
					<Code>CheckboxGroup.Checkbox</Code>.
				</Paragraph>
				<Header.H4>Before</Header.H4>
				<CodeBlock language="tsx">
					{`
<CheckboxGroup
	onChange={setSelected}
	value={selected}
	controls={[
		{ label: "White Wine", value: "white-wine" },
		{ label: "Red Wine", value: "red-wine" },
	]}
/>
`}
				</CodeBlock>
				<Header.H4>After</Header.H4>
				<CodeBlock language="tsx">
					{`
<CheckboxGroup onChange={setSelected} value={selected}>
	<CheckboxGroup.Checkbox value="white-wine">White Wine</CheckboxGroup.Checkbox>
	<CheckboxGroup.Checkbox value="red-wine">Red Wine</CheckboxGroup.Checkbox>
</CheckboxGroup>
`}
				</CodeBlock>
			</TextContainer>
		</ContentSection>
	</>
);

const BaseCheckboxExample = () => {
	const [checked, setChecked] = useState(false);
	return <BaseCheckbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />;
};

const CheckboxExample = () => {
	const [checked, setChecked] = useState(false);
	return (
		<Checkbox value="value" checked={checked} onChange={(e) => setChecked(e.target.checked)}>
			Label
		</Checkbox>
	);
};

//TODO: fix https://alfa.siteimprove.com/rules/sia-r60
/** @ignore [a11y:R60] */
const CheckboxGroupExample = () => {
	const [selected, setSelected] = useState<string[]>([]);
	return (
		<CheckboxGroup onChange={setSelected} value={selected}>
			<CheckboxGroup.Checkbox value="option-1">Regular option</CheckboxGroup.Checkbox>
			<CheckboxGroup.Checkbox value="option-2" indeterminate>
				Indeterminate option
			</CheckboxGroup.Checkbox>
			<CheckboxGroup.Checkbox value="option-3" disabled>
				Disabled option
			</CheckboxGroup.Checkbox>
			<CheckboxGroup.Checkbox value="option-4">
				<Icon>
					<IconApprove />
				</Icon>
				<InlineText>Option with left icon</InlineText>
			</CheckboxGroup.Checkbox>
			<CheckboxGroup.Checkbox value="option-5">
				{(props: { checked: boolean; disabled: boolean }) => (
					<>
						<InlineText
							emphasis={props.checked ? "medium" : "normal"}
							lineHeight="multi-line"
							tone={props.disabled ? "subtle" : undefined}
						>
							Option with right icon and dynamic text style
						</InlineText>
						<Icon>
							<IconApprove />
						</Icon>
					</>
				)}
			</CheckboxGroup.Checkbox>
			<Tooltip variant={{ type: "interactive" }} content="Extra info on the option 5">
				<CheckboxGroup.Checkbox value="option-6">Option with tooltip</CheckboxGroup.Checkbox>
			</Tooltip>
		</CheckboxGroup>
	);
};

/** @ignore [a11y:R60] */
const CheckboxGroupHorizontalExample = () => {
	const [selected, setSelected] = useState<string[]>([]);
	return (
		<CheckboxGroup onChange={setSelected} value={selected} direction="horizontal">
			<CheckboxGroup.Checkbox value="option-1">Regular option</CheckboxGroup.Checkbox>
			<CheckboxGroup.Checkbox value="option-2" indeterminate>
				Indeterminate option
			</CheckboxGroup.Checkbox>
			<CheckboxGroup.Checkbox value="option-3" disabled>
				Disabled option
			</CheckboxGroup.Checkbox>
			<CheckboxGroup.Checkbox value="option-4">
				<Icon>
					<IconApprove />
				</Icon>
				<InlineText>Option with left icon</InlineText>
			</CheckboxGroup.Checkbox>
			<Tooltip variant={{ type: "interactive" }} content="Extra info on the option 5">
				<CheckboxGroup.Checkbox value="option-6">Option with tooltip</CheckboxGroup.Checkbox>
			</Tooltip>
		</CheckboxGroup>
	);
};
